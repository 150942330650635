import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/organisms/Layout';

import TextBanner from '../../src/components/organisms/TextBanner'
import TwoCardsBlock from '../../src/components/organisms/TwoCardsBlock'

const PageTemplate = (pageProps) => {
  const accessor = Object.keys(pageProps.data)[0];

  let components;

    components = pageProps.data.wpPage['pageModules']['components'] || []
  
  components = components.map((component) => {
    return {
      name: component.__typename.split('_').pop(),
      data: component,
    };
  });

  return (
    <Layout
      title={pageProps.data[accessor].title}
      url={pageProps.data[accessor].uri}
      footerData={pageProps.data[accessor].footerFields}
    >
      {components.map((component, index) => {

      if (component.name == 'TextBanner') {
        return <TextBanner {...component.data} key={index} />
      }
    

      if (component.name == 'TwoCardsBlock') {
        return <TwoCardsBlock {...component.data} key={index} />
      }
    
        return <div>Error: The component {component.name} was not found</div>;
      })}
    </Layout>
  );
};

export default PageTemplate;


    export const query = graphql`
      query PageQuery607($id: String!) {
        wpPage(id: {eq: $id}) {
          title
          uri
          
    pageModules {
      components {
        __typename
         
 
        ... on WpPage_Pagemodules_Components_TextBanner {
          ...Page_TextBanner
        }
       
 
        ... on WpPage_Pagemodules_Components_TwoCardsBlock {
          ...Page_TwoCardsBlock
        }
      
      }
    }
  

          footerFields {
            ... on WpPage_Footerfields {
              backgroundColor
              footerCtaHeading
              overrideCtaLink {
                title
                url
              }
              hideCtaBlock
            }
          }
        }
      }
    `
  
